<template>
  <div :class="$style.main">
    <div :class="$style.header">
      <img
        alt="future-logo"
        width="180"
        :src="require('@/assets/images/future.svg')"
        :class="$style.logo"
      />
      <img
        alt="vw-logo"
        width="50"
        :src="require('@/assets/images/vw.png')"
        :class="$style.logo"
      />
    </div>
    <container>
      <img
        :class="[$style.spin]"
        :src="require('@/assets/images/spinner.svg')"
        alt="loading"
      />
      <p :class="$style.smallText">Did you know?</p>
      <p :class="$style.groundText">
        A typical gas powered car emits about 4.6 metric tons of carbon dioxide
        per year - that’s the weight of one elephant!
      </p>
    </container>
  </div>
</template>

<script>
import Container from "@/components/container";

export default {
  name: "Loading",
  components: { Container },
};
</script>

<style lang="scss" module>
.main {
  @apply overflow-x-hidden overflow-y-scroll h-screen w-screen z-10;
  .header {
    @apply flex justify-between mx-auto mt-5;
    max-width: 90vw;
  }
}

.logo {
  @apply flex-none drop-shadow-none select-none pointer-events-none;
}

.spin {
  @apply w-8 h-8 mx-auto select-none pointer-events-none;
  animation-name: spin;
  animation-duration: 1250ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.smallText {
  @apply text-sm;
}

.groundText {
  @apply text-xl font-semibold;
}
</style>
