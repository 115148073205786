<template>
  <overlay-modal
    v-if="disclaimerModalEnabled"
    @close="disclaimerModalEnabled = false"
  >
    <div :class="$style.disclaimerPrint">
      Disclaimer: Future estimates the carbon emissions of your daily choices
      and spending across home, mobility, food, apparel and other shopping.
      Future is designed to show how our lifestyle impacts the climate and
      promotes positive changes in behavior. In arriving at your estimated
      carbon footprint, Future draws on the latest peer-reviewed scientific
      research on climate impacts of certain services, products and product
      categories. Calculating a carbon footprint is a complex and continually
      evolving science. Your savings are calculated based on your past mileage
      and do not currently factor in the carbon emissions of making your current
      car or your new car. That's because too few auto manufacturers provide
      validated details of emissions from raw materials, production and
      shipping, making accurate comparisons difficult.
      <span v-if="showSavings">
        Your estimated savings in annual fuel costs are based on this week's
        average gasoline prices in your area. The price per gallon of gasoline
        may vary in the future, so your savings could be higher or lower. The
        estimated savings in fuel costs assumes you charge the the Electrify
        America fast-charging network as part of the ID.4 Charging Plan.
      </span>
    </div>
    <div :class="$style.disclaimerPrint">
      WHILE FUTURE STRIVES TO OFFER THE MOST DETAILED AND ACCURATE VIEW OF YOUR
      CLIMATE IMPACT OF ANY TOOL AVAILABLE TODAY, ITS CALCULATIONS DEPEND ON THE
      ACCURACY AND COMPLETENESS OF THE INFORMATION YOU SHARE WITH FUTURE AND HAS
      THE LIMITATIONS CONTAINED IN THE INDEPENDENT THIRD- PARTY RESEARCH AND
      SOURCES FUTURE USES TO BUILD ITS METHODOLOGY. THE REPORTS AND INFORMATION
      FUTURE PROVIDES SHOULD ALWAYS BE VIEWED AS AN ESTIMATE AND NOT AN EXACT
      REPRESENTATION AND ARE FOR INFORMATIONAL PURPOSES ONLY. THE FUTURE APP IS
      PROVIDED TO YOU BY FUTR, INC. FUTR, INC. IS NOT RESPONSIBLE FOR AND MAKES
      NO GUARANTEES, COVENANTS, WARRANTIES OR REPRESENTATIONS IN CONNECTION WITH
      THE FUTURE APP AND ANY RESULTS, INFORMATION OR CONTENT PROVIDED OR
      OTHERWISE MADE AVAILABLE TO YOU OR ITS ACCURACY, COMPLETENESS,
      CORRECTNESS, CURRENCY, TRUTHFULNESS OR VERACITY. FUTR SHALL NOT BE LIABLE
      FOR ANY DECISIONS YOU MAKE OR ANY ACTION OR INACTION YOU TAKE BASED ON THE
      ANY CONTENT OR INFORMATION YOU OBTAIN FORM YOUR USE OF THE FUTURE APP. See
      Future’s terms & conditions for additional information and your rights and
      obligations with respect to the Future service.
    </div>
  </overlay-modal>

  <phone-modal
    v-if="enterPhoneModalEnabled"
    @close="enterPhoneModalEnabled = false"
    @next="goToNextPage"
  />

  <div :class="$style.main">
    <logos-header />
    <container>
      <headline>You can make a real difference.</headline>
      <p :class="$style.extraPadding">
        By switching your {{ computedRewards.oldCar.year }}
        {{ computedRewards.oldCar.make }} {{ computedRewards.oldCar.model }} to
        an ID.4,
      </p>
      <img
        :src="require('@/assets/images/ID.4.jpg')"
        :class="$style.carImage"
        alt="ID.4"
      />

      <div id="just-a-spacer"></div>

      <p :class="$style.semiBoldText">every year, you'd save</p>
      <div :class="$style.resultBox">
        <p :class="$style.bigBoldText">
          {{ computedRewards.impactInTons }} metric tons
        </p>
        <p>of CO₂e</p>
      </div>

      <div :class="$style.resultBox">
        <p>
          That's
          <b>{{ (computedRewards.impactInTons * 3).toFixed(2) }} metric tons</b>
          over a typical 3-year lease.
        </p>
      </div>
      <div :class="$style.savingsBox" v-if="showSavings">
        <p :class="$style.semiBoldText">and would earn</p>
        <div :class="$style.resultBox">
          <p :class="$style.bigBoldText">
            ${{
              computedRewards.futureRewards
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}
          </p>
          <p>in cash.</p>
        </div>
        <p>
          Plus, save
          <b
            >${{
              computedRewards.gasolineSavings
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }}</b
          >
          a year at the pump.<br />
          More cash for you. Less carbon for the planet.
        </p>
      </div>

      <div :class="$style.disclaimer" @click="disclaimerModalEnabled = true">
        <p :class="$style.smallText">How do we do this?</p>
        <img :src="require('@/assets/images/info.svg')" alt="info-icon" />
      </div>

      <div id="just-a-spacer"></div>

      <button :class="$style.buttonDark" @click="enterPhoneModalEnabled = true">
        <!--Get Rewarded-->
        Collect your ClimatePoints
      </button>
    </container>
  </div>
</template>

<script>
import Container from "@/components/container";
import PhoneModal from "@/components/phoneModal";
import OverlayModal from "@/components/overlayModal";
import LogosHeader from "@/components/logosHeader";
import Headline from "@/components/headline";

export default {
  name: "Results",
  emits: ["next"],
  components: { Headline, LogosHeader, OverlayModal, PhoneModal, Container },
  props: ["computedRewards", "showSavings"],
  data() {
    return {
      enterPhoneModalEnabled: false,
      disclaimerModalEnabled: false,
    };
  },
  methods: {
    goToNextPage() {
      this.enterPhoneModalEnabled = false;
      (this.disclaimerModalEnabled = false), this.$emit("next");
    },
  },
};
</script>

<style lang="scss" module>
.main {
  @apply overflow-x-hidden overflow-y-scroll h-screen w-screen z-10;
  .header {
    @apply flex justify-between mx-auto mt-5;
    max-width: 90vw;
  }
}

.logo {
  @apply flex-none drop-shadow-none select-none pointer-events-none;
}

.disclaimer {
  @apply flex space-x-2 text-xs mx-auto cursor-pointer;
}

.bigBoldText {
  font-family: "ITC Clearface Std", sans-serif;
  font-size: 2.6em;
  font-weight: 600;
  line-height: 40px;
}

.carImage {
  @apply flex-none rounded-xl select-none shadow-lg mx-auto;
}

.semiBoldText {
  @apply font-semibold;
}

.buttonDark {
  @apply btn btn-primary rounded-full normal-case;
}

.extraPadding {
  @apply px-10;
}

.disclaimerPrint {
  @apply text-xs text-left;
}

.resultBox {
  @apply flex space-x-2 items-center justify-center;
}

.savingsBox {
  @apply flex flex-col space-y-4;
}
</style>
