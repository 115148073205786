// CSS
import "@/assets/styles/app.scss";
import "@/assets/styles/normalize.css";

// Essentials
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// Plugins
import Maska from "maska";

// Axios
import Axios from "axios";
import axios from "axios";
import axiosRetry from "axios-retry";

// eslint-disable-next-line no-undef
const API = process.env.VUE_APP_FUTURECAR_API;
axios.defaults.baseURL = API;
axios.defaults.method = "GET";

// Application Configuration
const app = createApp(App);
app.config.globalProperties.$http = Axios;
app.config.globalProperties.api = API;
app.config.globalProperties.$httpRetry = axiosRetry;

app.use(Maska);
app.use(store).use(router).mount("#app");
