<template>
  <div :class="$style.header">
    <a href="https://future.green" target="_blank">
      <img
        alt="future-logo"
        width="180"
        :src="require('@/assets/images/future.svg')"
        :class="$style.logo"
      />
    </a>
    <a href="https://www.vw.com/en/models/id-4.html" target="_blank">
      <img
        alt="vw-logo"
        width="50"
        :src="require('@/assets/images/vw.png')"
        :class="$style.logo"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: "logosHeader",
};
</script>

<style lang="scss" module>
.logo {
  @apply flex-none drop-shadow-none select-none pointer-events-none;
}
.header {
  @apply flex justify-between mx-auto mt-3;
  max-width: 90vw;
}
</style>
