<template>
  <div :class="$style.headline">
    <slot />
  </div>
</template>

<script>
export default {
  name: "headline",
};
</script>

<style lang="scss" module>
.headline {
  font-family: "ITC Clearface Std", sans-serif;
  font-weight: 500;
  font-size: 2.2em;
  line-height: 38px;
}
</style>
