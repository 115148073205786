import { createStore } from "vuex";

const _store = {
  state: {
    user: {
      data: "",
    },
  },
  mutations: {
    user: (state, payload) => {
      state.user = payload;
    },
  },
  actions: {},
  getters: {
    user(state) {
      return state.user;
    },
  },
};

const store = createStore(_store);
export default store;
