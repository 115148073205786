<template>
  <div :class="$style.container">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    document.documentElement.setAttribute("lang", "en");
    document.documentElement.setAttribute("data-theme", "light");
  },
};
</script>

<style lang="scss" module>
.blueWave {
  @apply absolute select-none pointer-events-none overflow-hidden;
  top: -350px;
}

.greenWave {
  @apply absolute bottom-0 select-none pointer-events-none w-screen;
  @media (max-height: 40rem) {
    @apply hidden;
  }
}

.bgGreen {
  background-color: #bfc6aa;
}

.bgYellow {
  background-color: #eee4ce;
}

.container {
  @apply h-screen w-screen overflow-hidden flex items-center justify-center;
  background-color: #bfc6aa;
}
</style>
