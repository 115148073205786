<template>
  <!-- iOS -->
  <a
    href="https://getfuturecard.com/drivebigger"
    target="_blank"
    :class="$style.box"
    v-if="display === 'iOS'"
  >
    <p>Get the Future app now.</p>
    <img
      :src="require('@/assets/images/appiStore.png')"
      alt="get-on-app-store"
      width="180"
      :class="$style.center"
    />
  </a>

  <!-- Android -->
  <a
    href="https://getfuturecard.com/drivebigger"
    target="_blank"
    :class="$style.box"
    v-if="display === 'Android'"
  >
    <p>Get the Future app now.</p>
    <img
      :src="require('@/assets/images/googlePlay.png')"
      alt="get-on-app-store"
      width="180"
      :class="$style.center"
    />
  </a>

  <!-- Web -->
  <a
    href="https://getfuturecard.com/drivebigger"
    target="_blank"
    :class="$style.box"
    v-if="display === 'QR'"
  >
    <p>Get the Future app now.</p>
    <QRCodeVue3
      :class="$style.center"
      :width="180"
      :height="180"
      value="https://getfuturecard.com/drivebigger"
      :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
      :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0 }"
      :dotsOptions="{
        type: 'dots',
        color: '#26249a',
        gradient: {
          type: 'linear',
          rotation: 0,
          colorStops: [
            { offset: 0, color: '#223f09' },
            { offset: 1, color: '#0e1e01' },
          ],
        },
      }"
      :backgroundOptions="{ color: '#ffffff00' }"
      :cornersSquareOptions="{ type: 'square', color: '#000000' }"
      :cornersDotOptions="{ type: undefined, color: '#000000' }"
      fileExt="png"
      myclass="my-qur"
      imgclass="img-qr"
      downloadButton="my-button"
      :downloadOptions="{ name: 'vqr', extension: 'png' }"
    />
  </a>
</template>

<script>
import QRCodeVue3 from "qrcode-vue3";
// eslint-disable-next-line no-undef
const UA = require("ua-parser-js");
// eslint-disable-next-line no-unused-vars
let deviceInfo = new UA();

export default {
  name: "getTheApp",
  components: { QRCodeVue3 },
  data() {
    return {
      os: {},
      display: "QR",
    };
  },
  async mounted() {
    this.os = deviceInfo ?? "";
    /*
     let iPhoneUA =
     "Mozilla/5.0 (iPhone; CPU iPhone OS 15_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.3 Mobile/15E148 Safari/605.1 NAVER(inapp; search; 1010; 11.11.6; 11PRO)";
     let androidUA =
     "Mozilla/5.0 (Linux; Android 12; SAMSUNG SM-G998B) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/17.0 Chrome/96.0.4664.104 Mobile Safari/537.36";
     let windowsUA =
     "Mozilla/5.0 (X11; U; Linux i686; en-US; rv:1.8.1.13) Gecko/20080325 Fedora/2.0.0.13-1.fc8 Firefox/2.0.0.13";
     this.os.setUA(androidUA);
     */

    if (this.os?.getOS()?.name === "Android") {
      this.display = "Android";
    } else if (this.os?.getOS()?.name === "iOS") {
      this.display = "iOS";
    } else {
      this.display = "QR";
    }
  },
};
</script>

<style lang="scss" module>
.box {
  @apply p-2 border border-black rounded-md text-lg bg-secondary py-4;
}

.center {
  @apply mx-auto mt-4;
  img {
    @apply mx-auto;
  }
}
</style>
