<template>
  <div :class="$style.overlay" />
  <div :class="$style.main">
    <div :class="$style.background">
      <img
        :src="require('@/assets/images/closeIcon.png')"
        alt="close-icon"
        :class="$style.iconFloatRight"
        width="20"
        @click="this.$emit('close')"
      />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "overlayModal",
  emits: ["close"],
};
</script>

<style lang="scss" module>
.main {
  @apply overflow-x-hidden z-30 rounded-3xl shadow-sm absolute flex items-start justify-center text-center overflow-y-visible mt-8;
  width: 90vw;
  max-height: 90vh;
  @screen sm {
    width: 450px;
  }
}

.overlay {
  @apply bg-black absolute h-screen w-screen opacity-25 z-20;
}

.background {
  @apply bg-primary text-secondary-focus flex flex-col text-center space-y-3 p-6;
  color: #fff9ee;
  width: 90vw;
  @screen sm {
    width: 450px;
  }
}

.iconFloatRight {
  @apply self-end cursor-pointer select-none;
}
</style>
